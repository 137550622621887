import { ICreateConversationParams } from "./ConveyApi";

export interface ILang {
  outputLanguage: string;
  neuralName: string;
}

export type TLanguageType = {
  value?: string;
  label?: string;
};

export type TDirection = "outbound" | "inbound" | "note";
export type TConnectCallType = "audio" | "video";
export type TSDKExperience = "video" | "chat" | "transcribe" | "connect";

export interface ICRUDLayout {
  tabs: ICRUDTab[];
}

export interface ICRUDTab {
  title: string;
  columns: ICRUDColumn[];
}

export interface ICRUDColumn {
  fields: ICRUDField[];
}

export interface ICRUDField {
  label: string;
  type: "switch" | "textarea" | "input" | "numericInput";
  name: string;
  dependsOn?: string;
}

export interface ICampaignEntity {
  group_id: string;
  campaign_id: string;
  group_name: string;
  status: string;
  campaign_name: string;
}

export interface IAgentEntity {
  session_id: string;
  fname: string;
  lname: string;
  user_id: string;
}

export interface IConveyAdminCRUDProps {
  $container: string;
}

export interface IConveyCallSimulatorProps {
  conversationParams: Partial<ICreateConversationParams>;
  $container: string;
}

export interface IConveyEmbedCampaignProps {
  campaignId: string;
  $container: string;
}

export interface ITextTranslateParams {
  inputText: string;
  outputLanguage: string;
}

export interface IConveyVideoRoom {
  roomId: string;
  roomName: string;
  conversationId: string;
}

export interface ITemplateEntity {
  agency_id: string;
  created_at: string;
  name: string;
  status: string;
  template_id: string;
  text: string;
  total_rows: number;
  type: string;
  updated_at: string;
}

export interface IVideoRoomParticipant {
  recipient_id: string;
  recipient_label: string;
  recipient_number: string | null;
  recipient_language: string | null;
}

export const CNeuralNames: Record<string, ILang> = {
  Afrikaans: { outputLanguage: "af-ZA", neuralName: "af-ZA-WillemNeural" },
  Arabic: { outputLanguage: "ar-IQ", neuralName: "ar-IQ-RanaNeural" },
  German: { outputLanguage: "de-DE", neuralName: "de-DE-KatjaNeural" },
  English: { outputLanguage: "en-US", neuralName: "en-US-JennyNeural" },
  French: { outputLanguage: "fr-FR", neuralName: "fr-FR-BrigitteNeural" },
  Italian: { outputLanguage: "it-IT", neuralName: "it-IT-ImeldaNeural" },
  Japanese: { outputLanguage: "ja-JP", neuralName: "ja-JP-NanamiNeural" },
  Korean: { outputLanguage: "ko-KR", neuralName: "ko-KR-BongJinNeural" },
  Malay: { outputLanguage: "ms-MY", neuralName: "ms-MY-YasminNeural" },
  Dutch: { outputLanguage: "nl-NL", neuralName: "nl-NL-FennaNeural" },
  Portuguese: { outputLanguage: "pt-BR", neuralName: "pt-BR-BrendaNeural" },
  Slovak: { outputLanguage: "sk-SK", neuralName: "sk-SK-ViktoriaNeural" },
  Spanish: { outputLanguage: "es-ES", neuralName: "es-ES-EstrellaNeural" },
  Swedish: { outputLanguage: "sv-SE", neuralName: "sv-SE-SofieNeural" },
  Turkish: { outputLanguage: "tr-TR", neuralName: "tr-TR-EmelNeural" },
  Indian: { outputLanguage: "ta-IN", neuralName: "ta-IN-PallaviNeural" },
  Ukrainian: { outputLanguage: "uk-UA", neuralName: "uk-UA-PolinaNeural" },
  Chinese: { outputLanguage: "zh-CN", neuralName: "zh-CN-YunxiNeural" }
};

export const CNueralDialectMap = {
  af: "Afrikaans",
  ar: "Arabic",
  de: "German",
  en: "English",
  fr: "French",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  ms: "Malay",
  nl: "Dutch",
  pt: "Portuguese",
  sk: "Slovak",
  es: "Spanish",
  sv: "Swedish",
  tr: "Turkish",
  ta: "Indian",
  uk: "Ukrainian",
  zh: "Chinese"
};

export const CAutoDetectLangs: Record<string, string> = {
  Arabic: "ar-AE",
  Bengali: "bn-IN",
  Bulgarian: "bg-BG",
  Catalan: "ca-ES",
  Chinese: "zh-CN",
  Dutch: "nl-NL",
  English: "en-US",
  French: "fr-FR",
  German: "de-DE",
  Hindi: "hi-IN",
  Indonesian: "id-ID",
  Italian: "it-IT",
  Japanese: "ja-JP",
  Korean: "ko-KR",
  Polish: "pl-PL",
  Portuguese: "pt-BR",
  Russian: "ru-RU",
  Spanish: "es-ES",
  Swedish: "sv-SE",
  Tamil: "ta-IN",
  Thai: "th-TH",
  Turkish: "tr-TR",
  Ukrainian: "uk-UA",
  Vietnamese: "vi-VN"
};

export type TLoadingTypes = "transcribe" | "video" | "sms";

export interface IDialect {
  label: string;
  code: string;
}

export interface IVideoRoomResponse {
  status: string;
  room_id: string;
  conversation_id: string;
}

export interface IMessageEntity {
  conversation_id: string;
  message_id: string;
  direction: "inbound" | "outbound";
  body: string;
  body_transl: string | null;
  language: string;
  status: string;
  delivered_at: string;
  created_at: string;
  updated_at: string;
  recipient_label: string;
  recipient_number: string;
  source: string;
  metadata: string | null;
}

export interface IConversationEntity {
  closed_at: string;
  number_id: string;
  operator_id: string;
  agency_id: string;
  created_at: string;
  metadata: Record<any, any> | null;
  label: string;
  message_count: number;
  number: string;
  updated_at: string;
  conversation_id: string;
  recipients: IRecipientEntity[];
  status: string;
  last_message_at: string;
  unread: number;
  recent_message: string;
  primary_recipient: string;
  note: string | null;
  metadata: Record<any, any> | null;
  total_rows: number | null;
  channel: string | null;
  source: string | null;
  artifact_id: string | null;
  artifact_uri: string | null;
  resource_file: string | null;
  direction: string | null;
  chatbot: boolean | null;
  artifact_hash: string | null;
  operator_name: string | null;
  resource_id: string | null;
  connection_state: string | null;
  summary: string | null;
}

export interface IDialectEntity {
  code: string;
  label: string;
}

export interface IRecipientEntity {
  number: string;
  recipient_id: string;
  label: string;
  language: string;
  agency_id: string;
  connection_state: string;
}

export interface IConveyProps {
  agencyId: string;
  agencySecret: string;
  phoneNumber: string;
  sessionId: string;
  $container: string;
  conversation: IConversationEntity;
}

export interface IConveyApiOpts {
  agencyId?: string;
  agencySecret?: string;
  userJwt?: string;
}

export interface IConveyOpts {
  agencyId?: string;
  agencySecret?: string;
  phoneNumber?: string;
  userJwt?: string;
  forceInit?: boolean;
  options?: {
    showModal?: boolean;
    verticalVideo?: boolean;
    disableConveyConnect?: boolean;
    disableVideoCall?: boolean;
    disableJoinTranscribe?: boolean;
    disableLocationRequest?: boolean;
    disableVideoTranscribe?: boolean;
    disableReassignAgent?: boolean;
    enableSentimentAnalysis?: boolean;
    enableKeywordBubbles?: boolean;
    enableTTS?: boolean;
    disableShowLiveAudio?: boolean;
    enableInterpreter?: boolean;
    enableVoice2Voice?: boolean;
    openLastActiveChat?: boolean;
  };
}

export interface IConveySMSChatProps {
  $container: string;
  ui?: boolean;
  language?: string;
  greetingMessage?: string;
  conversationId?: string;
}

export interface IJoinVideoParams {
  conversationId?: string;
  videoRoomId?: string;
}

export interface IConveyVideoCallProps {
  invitationMessage: string;
  transcribe: boolean;
  record: boolean;
  blur: boolean;
  enableAudio: boolean;
  enableVideo: boolean;
  $container: string;
  $transcriptionContainer?: string;
  joinVideoRoomParams?: IJoinVideoParams;
}

export interface IConveyConnectCallProps {
  $container: string;
  callType: TConnectCallType;
}

export interface IConveyTranscribeCallProps {
  correlationId: string;
  $container: string;
  callerLanguage: string;
  neuralName: string;
  $summaryContainer?: string;
  $sentimentContainer?: string;
  $keywordsContainer?: string;
  talkback?: boolean;
  conversationId?: string;
  showUploader?: boolean;
  recipientAudio?: File;
  agentAudio?: File;
  dualChannel?: boolean;
}

export interface IConveyJoinSMSChatParams {
  phoneNumber: string;
  $container: string;
}

export interface IConveyJoinVideoCallParams {
  $container: string;
}

export interface IConveyEndVideoCallParams {
  phoneNumber: string;
}

export interface IConveyHasSMSChatParams {
  phoneNumber: string;
}

export interface IConveyEndSMSChatParams {
  phoneNumber: string;
  message?: string;
}

export interface IConveyLocationRequestParams {
  phoneNumber: string;
  message?: string;
}

export const CEndpoints = {
  updateMetadata: "/api/v1/metadata/:objectType/:objectId",
  getAttachments: "/api/v1/attachments",
  downloadAttachment: "/api/v1/attachments/:id/download?stream=1",
  createAccessToken: "/api/v1/auth/:agency_id/token",
  createSpeechSynthesis: "/api/v1/speech/synthesis",
  createLanguageDetection: "/api/v1/language/detection",
  createLanguageSelection: "/api/v1/language/selection",
  toggleVoiceToVoice: "/api/v1/voice-to-voice/toggle",
  createLanguageIdentification: "/api/v1/language/identification",
  getAgency: "/api/v1/agencies/:agency_id",
  createConversation: "/api/v1/conversations",
  getConversations: "/api/v1/conversations",
  getConversation: "/api/v1/conversations/:id",
  updateConversation: "/api/v1/conversations/:id",
  getConversationSummary: "/api/v1/conversations/:id/summarize",
  closeConversation: "/api/v1/conversations/:id/close",
  getMessages: "/api/v1/conversations/:id/messages",
  getSourceMessages: "/api/v1/messages",
  getKeywords: "/api/v1/keywords",
  getCurrentUser: "/api/v1/users/me",
  getDialects: "/api/v1/text_translate",
  bindUserToAgency: "/api/v1/sessions/:id",
  getSessions: "/api/v1/sessions",
  updateAgent: "/api/v1/conversations/:conversation_id/assign/:agent_id",
  getTemplates: "/api/v1/agencies/:id/templates",
  updateRecipients: "/api/v1/conversations/:id/recipients",
  getRecipientGeolocations: "/api/v1/recipients/:id/geolocations",
  putRecipientGeolocations: "/api/v1/recipients/:id/geolocations",
  postMessages: "/api/v1/messages",
  postRecipients: "/api/v1/recipients",
  postTokens: "/api/v1/tokens",
  getToken: "/api/v1/tokens/:id",
  postVideoRooms: "/api/v1/videorooms",
  getVideoRoom: "/api/v1/videorooms/:room_id",
  getVideoRoomParticipants: "/api/v1/videorooms/:room_id/participants",
  deleteVideoRoomParticipants: "/api/v1/videorooms/:room_id/participants/:participantId",
  getVideoRooms: "/api/v1/videorooms",
  deleteVideoRooms: "/api/v1/videorooms/:room_id",
  postTextTranslate: "/api/v1/text_translate",
  postVideoRoomTokens: "/api/v1/videorooms/:video_room_id/tokens",
  putRecipientLanguage: "/api/v1/recipients/:recipient_id/dialect/:language",
  getCampaignGroup: "/api/v1/campaigns/:id/groups",
  postCampaignSubscribe: "/api/v1/campaigns/:id/subscribers",
  getCampaignSubscriber: "/api/v1/campaigns/:id/subscribers/:subscriber_id",
  updateCampaignSubscriber: "/api/v1/campaigns/:id/subscribers/:subscriber_id",
  deleteCampaignSubscriber: "/api/v1/campaigns/:id/subscribers/:subscriber_id",
  getConnectToken: "/api/v1/providers/connect/token",
  putAgencySettings: "/api/v1/agencies/:id/settings",
  getAgencyPositions: "/api/v1/agencies/:id/positions",
  putAgency: "/api/v1/agencies/:id"
};
